import React from "react";
import {
  DEGRADED,
  OFFLINE,
  OPERATIONAL,
  SCHEDULED_MAINTENANCE,
} from "../constants/conditions";

import BannerIncident from "./common/BannerIncident";
import { usePayloadContext } from "components/usePayloadContext";
import dayjs from "dayjs";

const MAINTENANCE = "MAINTENANCE";

const ActiveIncidents = () => {
  const all = usePayloadContext();
  const { activeIncidents, conditions, scheduledMaintenances, config } = all;
  const offlineIncidents = [];
  const otherActiveIncidents = [];
  const maintenanceIncidents = [];
  activeIncidents?.forEach((incident) => {
    const isOffline =
      Object.keys(incident?.componentConditions || {})?.filter(
        (key) =>
          conditions[incident?.componentConditions?.[key]] === OFFLINE &&
          incident?.severitySlug !== MAINTENANCE
      ).length > 0;
    if (isOffline) {
      offlineIncidents.push(incident);
    } else if (incident?.severitySlug === MAINTENANCE) {
      maintenanceIncidents.push(incident);
    } else {
      otherActiveIncidents.push(incident);
    }
  });
  return (
    <div className="my-10">
      <h2 className="text-xl font-medium pb-4">System Status</h2>
      {offlineIncidents?.map((incident) => {
        return (
          <BannerIncident
            key={incident.id}
            bannerType={OFFLINE}
            incident={incident}
          />
        );
      })}
      {otherActiveIncidents?.map((incident) => {
        return (
          <BannerIncident
            key={incident.id}
            bannerType={DEGRADED}
            incident={incident}
          />
        );
      })}
      {maintenanceIncidents?.map((incident) => {
        return (
          <BannerIncident
            key={incident.id}
            bannerType={SCHEDULED_MAINTENANCE}
            incident={incident}
          />
        );
      })}
      {scheduledMaintenances?.map((maintenance) => {
        // There is an open incident so dont duplicate
        if (!maintenance?.incidentId) {
          const isActive = dayjs().isAfter(dayjs(maintenance?.startsAt)) && dayjs().isBefore(dayjs(maintenance?.endsAt));
          return (
            <BannerIncident
              key={maintenance?.id}
              startTime={maintenance?.startsAt}
              endTime={maintenance?.endsAt}
              bannerType={SCHEDULED_MAINTENANCE}
              title={isActive ? "System is undergoing a scheduled maintenance" : `Upcoming Maintenance: ${maintenance?.name}`}
              latestNote={maintenance.summary}
            />
          );
        }
      })}
      {!offlineIncidents?.length &&
        !otherActiveIncidents?.length &&
        !maintenanceIncidents?.length &&
        !scheduledMaintenances?.length && (
          <BannerIncident
            bannerType={OPERATIONAL}
            title={"All systems operational"}
            latestNote={config?.operationalMessage || "Nothing to report."}
          />
        )}
    </div>
  );
};

export default ActiveIncidents;
