import React from "react";

// Phase-based color schemes
const phaseColorSchemes = {
  STARTED: `border-fh-tomato-7 text-fh-tomato-light-11 bg-fh-tomato-light-2 dark:bg-fh-tomato-dark-6 dark:text-fh-tomato-dark-12 dark:border-fh-tomato-dark-11 font-medium`,
  ACTIVE: `border-fh-amber-7 text-fh-amber-light-11 bg-fh-amber-light-2 dark:bg-fh-amber-dark-6 dark:text-fh-amber-dark-12 dark:border-fh-amber-dark-11 font-medium`,
  POST_INCIDENT: `border-fh-green-7 text-fh-green-light-11 bg-fh-green-light-2 dark:bg-fh-green-dark-6 dark:text-fh-green-dark-12 dark:border-fh-green-dark-11 font-medium`,
  CLOSED: `border-fh-green-7 text-fh-green-light-11 bg-fh-green-light-2 dark:bg-fh-green-dark-6 dark:text-fh-green-dark-12 dark:border-fh-green-dark-11 font-medium`,
  DEFAULT: `border-fh-gray-7 text-fh-gray-light-11 bg-fh-gray-light-2 dark:bg-fh-gray-dark-6 dark:text-fh-gray-dark-12 dark:border-fh-gray-dark-11 font-medium`,
};

// Legacy milestone mapping (for backward compatibility)
const milestoneColorSchemes = {
  started: phaseColorSchemes.STARTED,
  detected: phaseColorSchemes.STARTED,
  acknowledged: `border-fh-orange-7 text-fh-orange-light-11 bg-fh-orange-light-2 dark:bg-fh-orange-dark-6 dark:text-fh-orange-dark-12 dark:border-fh-orange-dark-11 font-medium`,
  investigating: phaseColorSchemes.ACTIVE,
  identified: phaseColorSchemes.ACTIVE,
  mitigated: phaseColorSchemes.ACTIVE,
  resolved: phaseColorSchemes.POST_INCIDENT,
  "retrospective started": phaseColorSchemes.POST_INCIDENT,
  "retrospective completed": phaseColorSchemes.POST_INCIDENT,
};

const Tag = ({ children, className, size, variant, phase }) => {
  let variantClassName = "";
  let sizeClassName = "";
  if (variant === "greyed") {
    variantClassName =
      "border-fh-gray-light-8 text-fh-gray-light-11 dark:border-fh-gray-dark-11 dark:text-fh-gray-dark-12";
  }

  if (variant === "lighter") {
    variantClassName =
      "border-fh-gray-light-8 text-fh-gray-light-12 dark:bg-fh-gray-dark-1 dark:text-fh-gray-dark-12 dark:border-fh-gray-dark-12 font-medium";
  }

  if (variant === "milestone") {
    if (phase) {
      // Use phase-based styling if phase is provided
      variantClassName = phaseColorSchemes[phase] || phaseColorSchemes.DEFAULT;
    } else {
      // Fall back to legacy behavior for backward compatibility
      const milestone = children.toLowerCase();
      variantClassName = milestoneColorSchemes[milestone] || phaseColorSchemes.DEFAULT;
    }
  }

  if (size === "xs") {
    sizeClassName = "text-xs py-0.5 px-1.5";
  }

  if (size === "sm") {
    sizeClassName = "text-xs py-1.5 px-2";
  }

  if (size === "xxs") {
    sizeClassName = "text-[10px] leading-none";
  }

  return (
    <div
      className={`py-1 px-2 font-medium border border-fh-gray-6 dark:border-fh-gray-11 rounded-full mr-2 ${variantClassName} ${sizeClassName} ${className}`}
    >
      {children}
    </div>
  );
};

export default Tag;