import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { LeftChevron, RightChevron } from "./Icons";
import IncidentHoverWrapper from "./common/IncidentHoverWrapper";
import IncidentRow from "./common/IncidentRow";
import Markdown from "components/shared/Markdown";
import { usePayloadContext } from "components/usePayloadContext";
import {
  getNoteFromEvent,
  getLatestNoteEvent,
  getLatestMilestoneFromTimestamps,
  getMilestonePhase,
} from "v2/helpers/timeline";

const MonthlyIncidentsSection = () => {
  const { incidentMap, allIncidents } = usePayloadContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [monthYear, setMonthYear] = React.useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  useEffect(() => {
    const monthYearParam = searchParams.get("month-year");
    if (monthYearParam) {
      const [year, month] = monthYearParam.split("-").map(Number);
      if (!isNaN(year) && !isNaN(month) && month >= 0 && month <= 11) {
        setMonthYear({ month, year });
      }
    }
  }, [searchParams]);

  const updateMonthYear = (newMonthYear) => {
    setMonthYear(newMonthYear);
    setSearchParams({
      "month-year": `${newMonthYear.year}-${newMonthYear.month}`,
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-10">
        <h3 className="font-medium text-xl ml-1">
          {dayjs()
            .month(monthYear.month)
            .year(monthYear.year)
            .format("MMMM YYYY")}
        </h3>
        <div className="flex gap-2">
          <button
            className="border p-2 border-fh-gray-6 dark:border-fh-gray-dark-6 rounded-full hover:bg-white dark:hover:bg-fh-gray-dark-3"
            onClick={() => {
              let prevMonth = monthYear.month - 1;
              let year = monthYear.year;
              if (prevMonth < 0) {
                year = monthYear.year - 1;
                prevMonth = 11;
              }

              updateMonthYear({
                month: prevMonth,
                year: year,
              });
            }}
          >
            <div className="mr-0.5">
              <LeftChevron />
            </div>
          </button>
          <button
            className="border p-2 border-fh-gray-6 dark:border-fh-gray-dark-6 rounded-full hover:bg-white dark:hover:bg-fh-gray-dark-3 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={
              monthYear.month === new Date().getMonth() &&
              monthYear.year === new Date().getFullYear()
            }
            onClick={() => {
              let nextMonth = monthYear.month + 1;
              let year = monthYear.year;
              if (nextMonth > 11) {
                year = monthYear.year + 1;
                nextMonth = 0;
              }
              if (
                year < new Date().getFullYear() ||
                (nextMonth <= new Date().getMonth() &&
                  year === new Date().getFullYear())
              ) {
                updateMonthYear({
                  month: nextMonth,
                  year: year,
                });
              }
            }}
          >
            <div className="ml-0.5">
              <RightChevron />
            </div>
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center mt-4 mb-24 rounded border bg-white border-fh-gray-6 dark:border-fh-gray-dark-6 dark:bg-fh-gray-dark-3 p-6 gap-y-4">
        <div className="flex flex-col">
          {Object.values(incidentMap[monthYear.year]?.[monthYear.month] || {})
            .flat()
            .filter((incident) => incident.severitySlug !== "MAINTENANCE")
            ?.length === 0 && (
            <div className="text-fh-gray-11 dark:text-fh-gray-dark-11 text-sm">
              No incidents found for this month.
            </div>
          )}
          {Object.keys(incidentMap[monthYear.year]?.[monthYear.month] || {})
            ?.reverse()
            ?.map((incidentDay, i) => {
              return (
                <div
                  className="flex flex-col"
                  key={`${monthYear.year}-${monthYear.month}-${i}`}
                >
                  {incidentMap[monthYear.year]?.[monthYear.month]?.[
                    incidentDay
                  ]?.map((incident) => {
                    const lastNoteEvent = getLatestNoteEvent(
                      incident?.timeline
                    );
                    const milestone = getLatestMilestoneFromTimestamps(
                      incident.timestamps,
                      incident.lifecyclePhases
                    );
                    const phase = getMilestonePhase(milestone?.slug, incident.lifecyclePhases);
                    const lastNote = getNoteFromEvent(lastNoteEvent);
                    if (incident?.severitySlug !== "MAINTENANCE") {
                      return (
                        <div key={incident.id}>
                          <IncidentHoverWrapper
                            incidentId={incident.id}
                            className="mx-[-8px] py-3 px-1"
                            monthYear={monthYear}
                          >
                            <h3 className="mb-2">{incident?.title}</h3>
                            <IncidentRow
                              milestone={milestone?.name}
                              timestamp={incident?.timestamps?.started}
                              phase={phase}
                            >
                              {lastNote && (
                                <div className="mt-2 dark:text-fh-gray-dark-11">
                                  <Markdown source={lastNote} />
                                </div>
                              )}
                              {Object.keys(incident?.componentConditions || {})
                                ?.length > 0 && (
                                <p className="mt-2 text-s">
                                  <span className="font-medium">
                                    Affected components:
                                  </span>{" "}
                                  {Object.keys(
                                    incident?.componentConditions
                                  ).join(", ")}
                                </p>
                              )}
                            </IncidentRow>
                          </IncidentHoverWrapper>
                          <hr className="my-6 dark:border-fh-gray-dark-6" />
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MonthlyIncidentsSection;
