import Markdown from "components/shared/Markdown";
import React from "react";
import Tag from "v2/components/common/Tag";
import Timestamp from "v2/components/common/Timestamp";

const IncidentRow = ({
  title,
  milestone,
  timestamp,
  content,
  children,
  phase
}) => {
  const displayMilestone = milestone?.startsWith("postmortem_") ?
    milestone.replace("postmortem_", "retrospective ") :
    milestone;

  return (
    <div className="flex">
      <div className="text-sm">
        <div className={`flex ${milestone ? "ml-[-2px]" : ""}`}>
          {milestone && (
            <div className="flex gap-2 items-center">
              <Tag size="xs" className="capitalize" variant="milestone" phase={phase}>
                {displayMilestone}
              </Tag>
            </div>
          )}
          <div className="flex flex-wrap items-center">
            <div className="text-fh-gray-light-11 dark:text-fh-gray-dark-11 text-xs">
              <Timestamp timestamp={timestamp} />
            </div>
          </div>
        </div>
        <div className="">
          {title && <h3 className="text-md mt-2">{title}</h3>}
          {content && (
            <div className="text-fh-gray-light-12 dark:text-fh-gray-dark-12">
              <Markdown source={content} />
            </div>
          )}
          {children}
        </div>
      </div>
      <hr></hr>
    </div>
  );
};

export default IncidentRow;
